import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [isAuth, setAuth] = useState(false);
    const [isAdmin, setAdmin] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        // Token kontrolü ve state güncelleme mantığı buraya gelecek
    }, []);

    return (
        <AppContext.Provider value={{ isAuth, setAuth, isAdmin, setAdmin, userId, setUserId }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);