import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AdminCollectionList.css';

const AdminCollectionList = () => {
    const [collections, setCollections] = useState([
        {
            _id: '1',
            name: 'Sample Collection',
            imageUrl: 'https://via.placeholder.com/200',
        }
    ]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCollections();
    }, []);

    const fetchCollections = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/collections`);
            setCollections(res.data);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/collections/${id}`);
            setCollections(collections.filter(collection => collection._id !== id));
        } catch (error) {
            console.error('Error deleting collection:', error);
        }
    };

    const handleAddCollection = () => {
        navigate('/admin/add-collection');
    };

    const handleEditCollection = (id) => {
        navigate(`/admin/edit-collection/${id}`);
    };

    return (
        <div className="collection-grid-container">
            <div className="header">
                <h2>Koleksiyonlar</h2>
                <button className="btn btn-primary" onClick={handleAddCollection}>Koleksiyon Ekle</button>
            </div>
            <div className="collection-grid">
                {collections.map(collection => (
                    <div className="collection-card" key={collection._id}>
                        <img src={collection.imageUrl} alt={collection.name} className="collection-image" />
                        <div className="collection-info">
                            <h5>{collection.name}</h5>
                        </div>
                        <div className="collection-actions">
                            <button className="btn btn-warning" onClick={() => handleEditCollection(collection._id)}>Düzenle</button>
                            <button className="btn btn-danger" onClick={() => handleDelete(collection._id)}>Sil</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminCollectionList;
