import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import axios from 'axios';
import './Carousel.css';

const CustomCarousel = () => {
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        fetchBanners();
    }, []);

    const fetchBanners = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/banners`);
            setBanners(res.data);
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    return (
        <div className="carousel-container">
            <Carousel indicators={false}>
                {banners.map((banner, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={banner.imageUrl}
                            alt={`Slide ${index}`}
                        />
                        <Carousel.Caption>
                            <h3>{banner.title}</h3>
                            <p>{banner.description}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
}

export default CustomCarousel;
