import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './ProductDetail.css';
import { useAppContext } from '../contexts/AppContext';
import SimilarProducts from './SimilarProducts';

const ProductDetail = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedColor, setSelectedColor] = useState('');
    const [showDescription, setShowDescription] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const [similarProducts, setSimilarProducts] = useState([]);
    const { updateCounts } = useAppContext();
    const [updateTrigger, setUpdateTrigger] = useState(0);
    const [isZoomed, setIsZoomed] = useState(false);
    const imageRef = useRef(null);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`);
                setProduct(response.data);
                setSelectedImage(response.data.mainImageUrl);
            } catch (error) {
                console.error('Error fetching the product:', error);
            }
        };
        fetchProduct();
    }, [id]);

    if (!product) return <div>Loading...</div>;

    const toggleSizeSelection = (size) => {
        if (selectedSizes.includes(size)) {
            setSelectedSizes(selectedSizes.filter(s => s !== size));
        } else {
            setSelectedSizes([...selectedSizes, size]);
        }
    };

    const handleColorSelection = (color) => {
        setSelectedColor(color);
    };

    const handleAddToCart = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found, please log in.');
                return;
            }
    
            if (!selectedColor) {
                console.error('Please select a color.');
                return;
            }
    
            if (selectedSizes.length === 0) {
                console.error('Please select a size.');
                return;
            }
    
            const cartItems = selectedSizes.map(size => ({
                productId: product._id,
                quantity: 1,
                size: size,
                color: selectedColor // Seçilen renk
            }));
    
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/cart/add`, { 
                productId: product._id, 
                quantity: 1, 
                size: selectedSizes[0], 
                color: selectedColor 
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            console.log('Products added to cart:', response.data);
            // await updateCounts();
            setUpdateTrigger(prev => prev + 1); // Yeniden render'ı tetikle
        } catch (error) {
            console.error('Sepete eklerken hata:', error.response ? error.response.data : error.message);
        }
    };
    
    const handleAddToFavorites = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Token bulunamadı, lütfen giriş yapın.');
                return;
            }
                console.error('Token bulunamadı, lütfen giriş yapın.');
            const url = `${process.env.REACT_APP_API_URL}/favorites/${isFavorite ? 'remove' : 'add'}`;
            const response = await axios.post(url, 
                { productId: product._id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (response.data.success) {
                setIsFavorite(!isFavorite);
                console.log(`Ürün ${product._id} favorilere ${!isFavorite ? 'eklendi' : 'çıkarıldı'}`);
                await updateCounts();
                setUpdateTrigger(prev => prev + 1);
            } else {
                console.error('Favorilere eklerken hata:', response.data.message);
            }
        } catch (error) {
            console.error('Favorilere eklerken hata:', error.response ? error.response.data : error.message);
        }
    };

    const handleImageZoom = () => {
        setIsZoomed(!isZoomed);
    };

    const handleMouseMove = (e) => {
        if (!imageRef.current) return;
        const { left, top, width, height } = imageRef.current.getBoundingClientRect();
        const x = (e.clientX - left) / width * 100;
        const y = (e.clientY - top) / height * 100;
        imageRef.current.style.setProperty('--x', `${x}%`);
        imageRef.current.style.setProperty('--y', `${y}%`);
    };

    return (
        <div className="product-detail-container">
            <div className="product-detail">
                <div className="product-images">
                    <div 
                        ref={imageRef}
                        className={`main-image ${isZoomed ? 'zoomed' : ''}`}
                        onMouseEnter={() => setIsZoomed(true)}
                        onMouseLeave={() => setIsZoomed(false)}
                        onMouseMove={handleMouseMove}
                    >
                        <img src={selectedImage} alt={product.name} />
                        <button className="heart-button" onClick={handleAddToFavorites}>
                            <span className={isFavorite ? 'filled' : 'unfilled'}>♥</span>
                        </button>
                    </div>
                    <div className="thumbnail-images">
                        {product.imageUrls.map((img, index) => (
                            <img
                                key={index}
                                src={img}
                                alt={product.name}
                                onClick={() => setSelectedImage(img)}
                                className={img === selectedImage ? 'active' : ''}
                            />
                        ))}
                    </div>
                </div>
                <div className="product-info">
                    <h1>{product.name}</h1>
                    <p className="price">{product.price.toFixed(2)} TL</p>
                    
                    <div className="selection-container">
                        <div className="size-selection">
                            <h3>Beden:</h3>
                            <div className="sizes">
                                {product.sizes.map((size, index) => (
                                    <span
                                        key={index}
                                        className={selectedSizes.includes(size) ? 'size-selected' : ''}
                                        onClick={() => toggleSizeSelection(size)}
                                    >
                                        {size}
                                    </span>
                                ))}
                            </div>
                        </div>

                        <div className="color-selection">
                            <h3>Renk:</h3>
                            <div className="colors">
                                {product.colors && product.colors.map((color, index) => (
                                    <span
                                        key={index}
                                        className={selectedColor === color ? 'color-selected' : ''}
                                        onClick={() => handleColorSelection(color)}
                                    >
                                        {color}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>

                    <button className="add-to-cart" onClick={handleAddToCart}>SEPETE EKLE</button>

                    <div className="product-description">
                        <div className="description-header" onClick={() => setShowDescription(!showDescription)}>
                            <h3>Ürün Açıklaması</h3>
                            <span>{showDescription ? '-' : '+'}</span>
                        </div>
                        {showDescription && <p>{product.description || 'Ürün açıklaması bulunmamaktadır.'}</p>}
                    </div>
                </div>
            </div>
            {product && (
                <SimilarProducts 
                    currentProductId={product._id}
                    currentProductName={product.name}
                    currentProductDescription={product.description}
                />
            )}
        </div>
    );
};

export default ProductDetail;
