import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './AdminAddCollection.css';

const AdminAddCollection = () => {
    const [collection, setCollection] = useState({
        name: '',
        description: '',
        image: null
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        if (e.target.name === 'image') {
            setCollection({ ...collection, image: e.target.files[0] });
        } else {
            setCollection({ ...collection, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', collection.name);
        formData.append('description', collection.description);
        if (collection.image) {
            formData.append('image', collection.image);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/collections`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Koleksiyon eklendi:', response.data);
            navigate('/admin/collections');
        } catch (error) {
            console.error('Koleksiyon eklenirken hata:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="add-collection-container">
            <h2>Koleksiyon Ekle</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Koleksiyon İsmi</label>
                    <input
                        type="text"
                        name="name"
                        value={collection.name}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Açıklama</label>
                    <textarea
                        name="description"
                        value={collection.description}
                        onChange={handleChange}
                        className="form-control"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Resim</label>
                    <input
                        type="file"
                        name="image"
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <button type="submit" className="btn btn-primary">Koleksiyon Ekle</button>
            </form>
        </div>
    );
};

export default AdminAddCollection;
