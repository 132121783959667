import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import './CategoryCard.css';

const CategoryCard = () => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/collections`);
            setCategories(res.data);
        } catch (error) {
            console.error('Error fetching collections:', error);
        }
    };

    const handleNavigateToCategory = (categoryName) => {
        navigate(`/collections/${categoryName}`);
    };

    return (
        <Container className="category-section">
            {categories.map((category, index) => (
                <div key={index} className={`category-card ${index % 2 === 0 ? 'left' : 'right'}`}>
                    <div className="category-image-wrapper">
                        <img
                            src={category.imageUrl || 'https://via.placeholder.com/600x400'}
                            alt={category.name}
                            className="category-image"
                        />
                    </div>
                    <div className="category-content">
                        <h3 className="category-name">{category.name}</h3>
                        <p className="category-description">{category.description || 'Koleksiyon açıklaması'}</p>
                        <button className="category-button" onClick={() => handleNavigateToCategory(category.name)}>
                            {category.buttonText || 'Keşfet'}
                        </button>
                    </div>
                </div>
            ))}
        </Container>
    );
};

export default CategoryCard;
